import React, { useEffect, useState, useMemo, useCallback } from "react";
import ConsentBanner from "./ConsentBanner";
import Cookies from "universal-cookie";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

const GTAG_COOKIE_NAME = "gatsby-gdpr-google-tagmanager";
const COOKIE_PATH = "/";
const COOKIE_EXPIRY = new Date(
  new Date().setFullYear(new Date().getFullYear() + 1)
);

const CookieConsent = () => {
  const [consentDecisionMade, setConsentDecisionMade] = useState(true);
  const cookies = useMemo(() => new Cookies(), []);

  useEffect(() => {
    if (cookies.get(GTAG_COOKIE_NAME) !== undefined) {
      setConsentDecisionMade(true);
    } else {
      setConsentDecisionMade(false);
    }
  }, [cookies, setConsentDecisionMade]);

  const handleDecision = useCallback((outcome: boolean) => {
    cookies.set(GTAG_COOKIE_NAME, outcome, {
      expires: COOKIE_EXPIRY,
      path: COOKIE_PATH,
    });
    if (outcome) {
      initializeAndTrack(window.location);
    }
    setConsentDecisionMade(true);
  }, []);

  const onAccept = useCallback(() => {
    handleDecision(true);
  }, []);

  const onDeny = useCallback(() => {
    handleDecision(false);
  }, []);

  return consentDecisionMade ? (
    <></>
  ) : (
    <ConsentBanner onAccept={onAccept} onDeny={onDeny} />
  );
};

export default CookieConsent;
