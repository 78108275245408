import React from "react"

export const LogoC = ({ className = "w-50 h-50 xl:w-36 xl:h-36" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 115 115"
      className={className}
      fill="currentColor"
    >
      <path d="m89.8 82.7-.2.2c-6.8 6.8-15.7 10.5-25.3 10.5-19.7 0-35.8-16.1-35.8-35.9 0-19.8 16-35.9 35.8-35.9 9.3 0 18.1 3.6 24.8 10 .1.1.1.2.2.3 2 2 2 5.2 0 7.1-.9.9-2.2 1.4-3.5 1.5-1.3 0-2.5-.5-3.5-1.4l-.1-.1c-4.9-5-11.3-7.7-18.2-7.7-14.2 0-25.8 11.8-25.8 26.2 0 14.4 11.6 26.2 25.8 26.2 6.9 0 13.4-2.7 18.3-7.7 1.1-1.1 1.1-2.9 0-4s-2.9-1.1-4 0c-3.8 3.9-8.9 6-14.3 6-11.1 0-20.2-9.2-20.2-20.5S52.9 37 64 37c5.3 0 10.3 2.1 14.1 5.9 0 0 0 .1.1.1 2 2 4.7 3.2 7.5 3.2s5.5-1.1 7.5-3.1c3.9-3.9 4.1-10.2.6-14.4-.1-.2-.3-.4-.4-.6C85.6 20.3 75.2 16 64.2 16c-22.9 0-41.4 18.7-41.4 41.6 0 22.9 18.6 41.6 41.4 41.6 11 0 21.4-4.3 29.2-12.1l.1-.1c.9-.9 2.1-1.4 3.4-1.4 1.3 0 2.5.5 3.4 1.4.9.9 1.4 2.1 1.4 3.4 0 1.3-.5 2.5-1.4 3.4-.1.1-.1.1-.1.2-9.6 9.6-22.4 14.9-36 14.9-28.2 0-51.2-23-51.2-51.3C13 29.3 36 6.3 64.2 6.3c13.6 0 26.4 5.3 36.1 14.9 1.1 1.1 2.9 1.1 4 0s1.1-2.9 0-4C93.6 6.4 79.4.5 64.3.5 33 .5 7.5 26.1 7.5 57.5s25.5 57 56.8 57c15.2 0 29.4-5.9 40.2-16.7.1-.1.3-.3.4-.5 1.7-1.9 2.7-4.4 2.7-7 0-2.8-1.1-5.5-3.1-7.4-4.1-4-10.6-4.1-14.7-.2zM107.5 90.4z" />
      <path d="M53.5 57.5c0 6 4.8 10.8 10.8 10.8 5.9 0 10.8-4.8 10.8-10.8s-4.8-10.8-10.8-10.8c-6 0-10.8 4.8-10.8 10.8zm15.8 0c0 2.8-2.3 5.1-5.1 5.1s-5.1-2.3-5.1-5.1 2.3-5.1 5.1-5.1c2.9 0 5.1 2.3 5.1 5.1z" />
    </svg>
  )
}
