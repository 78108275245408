import { useStaticQuery, graphql } from "gatsby";

export const useHeaderQuery = () => {
  const headerData = useStaticQuery(graphql`
    query headerQuery {
      header: kontentItemHomepage {
        elements {
          email {
            value
          }
          phone {
            value
          }
          navigation_menu {
            value {
              ... on kontent_item_navigation_menu {
                id
                system {
                  type
                }
                elements {
                  name {
                    value
                  }
                  description {
                    value
                  }
                  primary_navigation_links {
                    value {
                      ... on kontent_item_navigation_menu_link {
                        ...NavigatioMenuLink
                      }
                    }
                  }
                  secondary_links_heading {
                    value
                  }
                  secondary_navigation_links {
                    value {
                      ... on kontent_item_navigation_menu_link {
                        ...NavigatioMenuLink
                      }
                    }
                  }
                }
              }
              ... on kontent_item_navigation_menu_link {
                ...NavigatioMenuLink
              }
              ... on kontent_item_navigation_sector_menu {
                id
                system {
                  type
                }
                elements {
                  name {
                    value
                  }
                  navigation_sector {
                    value {
                      ... on kontent_item_navigation_sector {
                        id
                        system {
                          type
                        }
                        elements {
                          name {
                            value
                          }
                          icon {
                            value {
                              codename
                            }
                          }
                          description {
                            value
                          }
                          images {
                            value {
                              description
                              url
                              width
                              height
                            }
                          }
                          links {
                            value {
                              ... on kontent_item_navigation_menu_link {
                                ...NavigatioMenuLink
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          secondary_navigation_menu {
            value {
              ... on kontent_item_navigation_menu_link {
                ...NavigatioMenuLink
              }
            }
          }
          mortgage_calculator {
            value {
              ... on kontent_item_mortgage_calculator_page {
                id
                system {
                  type
                }
                url
              }
            }
          }
        }
      }
    }
  `);

  return headerData.header?.elements;
};
