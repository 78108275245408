import { useStaticQuery, graphql } from "gatsby";

export const useFooterQuery = () => {
  const footerData = useStaticQuery(graphql`
    query footerQuery {
      footer: kontentItemHomepage {
        elements {
          email {
            value
          }
          facebook {
            value
          }
          footer_disclaimer {
            value
          }
          footer_links {
            value {
              ... on kontent_item_section {
                elements {
                  name {
                    value
                  }
                }
                system {
                  id
                }
                url
                id
              }
            }
          }
          footer_navigation_menu {
            value {
              ... on kontent_item_navigation_menu {
                id
                system {
                  type
                }
                elements {
                  name {
                    value
                  }
                  primary_navigation_links {
                    value {
                      ... on kontent_item_navigation_menu_link {
                        id
                        system {
                          type
                        }
                        elements {
                          name {
                            value
                          }
                          linked_page {
                            value {
                              ...kontentItemUrl
                            }
                          }
                          query_params {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          footer_text {
            value
          }
          instagram {
            value
          }
          linkedin {
            value
          }
          mortgage_calculator {
            value {
              ... on kontent_item_mortgage_calculator_page {
                id
                url
              }
            }
          }
          mortgage_calculator_description {
            value
          }
          phone {
            value
          }
          postal_address {
            value
          }
          social_media_description {
            value
          }
          social_media_header {
            value
          }
          x {
            value
          }
        }
      }
    }
  `);

  return footerData?.footer?.elements;
};
