Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultOptions = void 0;
var defaultOptions = exports.defaultOptions = {
  environments: ["production"],
  googleTagManager: {
    cookieName: "gatsby-gdpr-google-tagmanager",
    dataLayerName: "dataLayer",
    routeChangeEvent: "gatsbyRouteChange"
  }
};