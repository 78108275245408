var _require = require('../helper'),
  validGTMTrackingId = _require.validGTMTrackingId,
  getCookie = _require.getCookie;
var _require2 = require('./google-tag-manager'),
  addGoogleTagManager = _require2.addGoogleTagManager,
  initializeGoogleTagManager = _require2.initializeGoogleTagManager,
  trackGoogleTagManager = _require2.trackGoogleTagManager;
exports.initializeAndTrackGoogleTagManager = function (options, location) {
  if (getCookie(options.cookieName) === "true" && validGTMTrackingId(options)) {
    var environmentParamStr = "";
    if (options.gtmAuth && options.gtmPreview) {
      environmentParamStr = "&gtm_auth=" + options.gtmAuth + "&gtm_preview=" + options.gtmPreview + "&gtm_cookies_win=x";
    }
    addGoogleTagManager(options, environmentParamStr).then(function (status) {
      if (status) {
        initializeGoogleTagManager(options);
        trackGoogleTagManager(options, location);
      }
    });
  }
};