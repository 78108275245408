import React from "react";
import { GatsbyBrowser } from "gatsby";
import { Layout } from "./src/components/Layout/index";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return (
    <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
  );
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  return <Layout {...props}>{element}</Layout>;
};
