import React from "react";
import { withPrefix } from "gatsby";

type ButtonProps = {
  title: string;
  url: string;
  handleActiveId: (id: null | string) => void;
};
export const ArrowRightTitleBtn = ({
  title,
  url,
  handleActiveId,
}: ButtonProps) => {
  return (
    <li onClick={() => handleActiveId(null)}>
      <a
        href={withPrefix(url)}
        className="inline-flex items-center hover:underline"
      >
        {title}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="shrink-0 w-5 h-5 ml-1"
        >
          <path
            fillRule="evenodd"
            d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z"
            clipRule="evenodd"
          />
        </svg>
      </a>
    </li>
  );
};
