import React from "react";
import { containsHTML } from "./utils";

export const convertRawHtml = (rawData: string, className = "") => {
  return containsHTML(rawData) ? (
    <div className={className} dangerouslySetInnerHTML={{ __html: rawData }} />
  ) : (
    rawData
  );
};
