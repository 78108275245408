import React from "react";
import { Link, withPrefix } from "gatsby";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useDisplaySectorIconByCodename } from "../../../hooks/useDisplaySectorIconByCodename";
// import { LeftArrowIcon } from "../../../common/Icons/AllIcons";

type Props = {
  item: any;
  handleActiveId: (id: string | null) => void;
};

const MobileNavSectorMenu = ({ item, handleActiveId }: Props) => {
  const sectors = item.elements.navigation_sector.value;

  return (
    <div className="fixed overflow-auto z-70 inset-y-0 right-0 top-0 bg-slate-700 text-white py-4 px-6 w-full">
      <div className="grid h-full">
        <div>
          <div className="flex flex-wrap flex-row-reverse gap-6 justify-between items-center -ml-6">
            <button
              onClick={() => handleActiveId("open")}
              className="hover:underline focus:underline focus:outline-none // flex items-center transition"
            >
              <ArrowLeftIcon className="mr-1 w-4 h-4" />
              <span className="text-sm">Back</span>
            </button>

            <h2 className="bg-ice-500 text-white px-6 py-2 inline-block font-bold text-xl">
              {item.elements.name.value}
            </h2>
          </div>
          <nav
            id="nav-sectors-sm"
            role="navigation"
            aria-label="Sectors Navigation"
            className="mt-6"
          >
            <ul className="grid gap-6 grid-cols-[repeat(auto-fit,minmax(5rem,1fr))]">
              {sectors.map((sector) => (
                <li key={sector.id} onClick={() => handleActiveId(null)}>
                  <Link
                    // TO FIX
                    to={
                      sector.elements.links.value[0].elements.linked_page
                        .value[0].url
                    }
                    // to={withPrefix(sector.elements.sector.value?.[0]?.url)}
                    className="flex flex-col items-center text-center space-y-2 leading-tight font-bold group text-base text-white hover:scale-110 hover:text-ice-400 "
                  >
                    <div
                      className="flex flex-col justify-center items-center p-4 text-white mb-1.5
              [ bg-slate-100/50 group-hover:bg-slate-100 group-hover:text-slate-700 transition ]
              [ w-20 h-20 ]"
                    >
                      {useDisplaySectorIconByCodename(
                        sector.elements.icon?.value?.[0]?.codename ?? ""
                      )}
                    </div>
                    <span className="w-full break-words">
                      {sector.elements.name?.value}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default MobileNavSectorMenu;
