import React, { useEffect } from "react";
import NavMenuLink from "./NavMenuLink";
import { ArrowRightTitleBtn } from "../../common/button/ArrowRightTitleBtn";
import { convertRawHtml } from "../../../utils/reactUtils";

interface NavMenuProps {
  item: Queries.kontent_item_navigation_menu;
  navRef: React.MutableRefObject<HTMLLIElement | null>;
  activeId: string | null;
  handleActiveId: (id: string | null) => void;
}

export const NavMenu = ({
  item,
  navRef,
  activeId,
  handleActiveId,
}: NavMenuProps) => {
  const elements =
    item.elements as Queries.kontent_item_navigation_menu_elements;

  useEffect(() => {
    const clickEventHandler = (event: MouseEvent): void => {
      if (
        activeId == item.id &&
        navRef.current &&
        !navRef.current.contains(event.target as Node)
      )
        handleActiveId(null);
    };
    document.addEventListener("click", clickEventHandler);
    return () => document.removeEventListener("click", clickEventHandler);
  }, [activeId]);

  return (
    <div className="border-b-6 border-ice-500 shadow-lg pb-0.5 bg-white">
      <div className="bg-slate-600 text-white w-full p-10">
        <div className="xl:flex xl:justify-between max-w-9xl mx-auto">
          {/* LEFT CONTENT */}
          <div className="flex-1 basis-1/2 max-w-2xl pr-12 prose-">
            <h2 className="bg-ice-500 text-white px-6 py-2 inline-block font-bold text-2xl">
              {item?.elements?.name?.value}
            </h2>
            <div className="prose prose-strong:text-white mt-2 px-2 text-3xl text-white/60 leading-normal">
              {convertRawHtml(item?.elements?.description?.value ?? "")}
            </div>
          </div>
          {/* RIGHT CONTENT */}
          <div className="flex-1 text-white  basis-1/2 2xl:ml-20">
            <nav
              id="nav-services-lg"
              role="navigation"
              aria-label="label"
              className=""
            >
              <ul className="p-6">
                {(
                  item?.elements?.primary_navigation_links?.value?.filter(
                    (e) => e?.system?.type === "navigation_menu_link"
                  ) as Queries.kontent_item_navigation_menu_link[]
                )?.map((link) => (
                  <NavMenuLink
                    key={link?.id}
                    item={link}
                    handleActiveId={handleActiveId}
                  />
                ))}
              </ul>

              {item?.elements?.secondary_links_heading?.value &&
                item?.elements?.secondary_navigation_links?.value?.length && (
                  <div className="mt-8 pt-8 border-t border-white/20">
                    <h3 className="text-lg font-bold leading-tight bg-ice-500 px-6 py-2 inline-block">
                      {item?.elements?.secondary_links_heading?.value}
                    </h3>
                    <ul className="text-lg font-medium grid grid-cols-auto-fit-250 xl:grid-cols-2 gap-x-10 gap-y-4 p-6">
                      {(
                        item?.elements?.secondary_navigation_links?.value?.filter(
                          (e) => e?.system?.type === "navigation_menu_link"
                        ) as Queries.kontent_item_navigation_menu_link[]
                      ).map((link) => (
                        <ArrowRightTitleBtn
                          key={link.id}
                          handleActiveId={handleActiveId}
                          title={link?.elements?.name?.value ?? ""}
                          url={
                            (
                              link?.elements?.linked_page
                                ?.value?.[0] as Christie.KontentItem
                            )?.url
                          }
                        />
                      ))}
                    </ul>
                  </div>
                )}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
