import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import React, { Fragment } from "react";
import { CookieBannerProps } from "../../../types";

const CookieBanner = ({ onAccept, onDeny }: CookieBannerProps) => {
  return (
    <Transition show={true} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-90 overflow-y-auto"
        static={true}
        open={true}
        onClose={() => null}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-80 transition-opacity" />
        </TransitionChild>

        <div className="relative flex min-h-screen items-center justify-center p-4">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel className="relative w-auto max-w-8xl overflow-y-hidden bg-white p-6 shadow-lg">
              <div className="pt-6">
                <div className="-ml-6 sm:-mt-3">
                  <DialogTitle
                    as="h2"
                    className="heading--sticker text-lg md:text-xl lg:text-2xl"
                  >
                    Analytics and tracking cookies
                  </DialogTitle>
                </div>
              </div>
              <Description className="prose prose-brand mt-4">
                This website uses analytics and tracking cookies to provide the
                optimal user experience.
              </Description>
              <div className="flex justify-end">
                <div className="flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                  <div className="rounded-md shadow-sm">
                    {onAccept !== undefined && (
                      <button
                        className="uppercase flex items-center justify-center px-4 py-3 leading-5 text-white bg-ice-800 hover:bg-ice-600 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                        onClick={onAccept}
                      >
                        Accept
                      </button>
                    )}
                  </div>
                </div>
                <div className="flex-shrink-0 sm:order-3 sm:ml-2">
                  <button
                    className="uppercase font-bold flex items-center justify-center px-4 py-3 leading-5 text-ice-600 bg-white hover:bg-ice-500 hover:text-white focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                    onClick={onDeny}
                  >
                    Decline
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CookieBanner;
