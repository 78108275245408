import React, { useEffect } from "react";

export const useAddWatsonAssistantScript = () => {
  useEffect(() => {
    const scriptId = "watson-assistant";

    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.innerHTML = `
      async function onLoad(instance) {
                await instance.render();
            }

            function pollForElement(type, id, callback, interval = 100) {
                const polling = setInterval(() => {
                let element;
                if (type === "id") {
                    element = document.getElementById(id);
                } else {
                    element = document.querySelector(id);
                }
                if (element) {
                    callback(element);
                    clearInterval(polling);
                }
                }, interval);
            }

            function addRestartButton(element) {
                const elements = document.querySelectorAll(
                ".WACHeader__Container .WACHeader .WACHeader__Buttons.WACHeader__RightButtons"
                );
                const restartButton = \`
                <span class="cds--popover-container cds--popover--caret cds--popover--high-contrast cds--popover--left cds--tooltip cds--icon-tooltip">
                    <div class="cds--tooltip-trigger__wrapper">
                    <button
                        aria-describedby="tooltip-:r99:"
                        class="cds--btn--icon-only WACHeader__CloseButton WACDirectionHasReversibleSVG cds--btn cds--btn--md cds--layout--size-md cds--btn--ghost"
                        type="button"
                        id="restart-button"
                    >
                        <span>
                        <svg focusable="false" preserveAspectRatio="xMidYMid meet" fill="#FFF" width="16" height="16" viewBox="0 0 16 16" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" style="fill: #FFF !important">
                            <path d="M13,9c0,2.8-2.2,5-5,5s-5-2.2-5-5s2.2-5,5-5h3.1L9.3,5.8L10,6.5l3-3l-3-3L9.3,1.2L11.1,3H8C4.7,3,2,5.7,2,9s2.7,6,6,6 s6-2.7,6-6H13z"></path>
                        </svg>
                        </span>
                    </button>
                    <!-- Tooltip now positioned correctly inside the wrapper -->
                    <span id="tooltip-:r99:" role="tooltip" class="cds--popover">
                        <span class="cds--popover-content cds--tooltip-content">Restart Conversation</span>
                        <span class="cds--popover-caret"></span>
                    </span>
                    </div>
                </span>
                \`;

                if (elements.length > 1) {
                elements[elements.length - 1].insertAdjacentHTML(
                    "afterbegin",
                    restartButton
                );
                } else {
                element.insertAdjacentHTML("afterbegin", restartButton);
                }
            }

            window.watsonAssistantChatOptions = {
                integrationID: "676940ab-5ff2-4432-bd66-8da670ec5f59",
                region: "eu-gb",
                serviceInstanceID: "d8ad6580-8845-4e1d-9e4b-de0495a42aba",
                onLoad: async (instance) => {
                await instance.render();
                // instance.on({
                //   type: "restartConversation",
                //   handler: function () {
                //     pollForElement(
                //       "class",
                //       ".WACHeader__Container .WACHeader .WACHeader__Buttons.WACHeader__RightButtons",
                //       function (element) {
                //         addRestartButton(element);
                //         document
                //           .getElementById("restart-button")
                //           .addEventListener("click", async () => {
                //             await instance.restartConversation();
                //           });
                //       },
                //       100
                //     );
                //   },
                // });
                instance.on({
                    type: "chat:ready",
                    handler: function () {
                    const chatWindow = document.querySelector(".WACHomeScreen");
                    if (!chatWindow) return;

                    pollForElement("id", "WACHomeScreen__backButton", (element) => {
                        element.style.backgroundColor =
                        "var(--cds-chat-SECONDARY-color)";
                        element.style.left = "75%";
                    });
                    const buttons = document.querySelectorAll(
                        ".WACHeader__CloseButton"
                    );
                    buttons.forEach((button) => {
                        button.firstChild.remove();
                        button.innerHTML = \`<span style="color: white !important;">—</span>\`;
                    });

                    // ADD IMAGE
                    const imageElement = chatWindow.querySelector(
                        ".WACHomeScreen__avatarHolder"
                    );
                    imageElement.remove();
                    const whiteImgUrl =
                        "https://assets-eu-01.kc-usercontent.com/5ceaca48-60e2-018e-49b4-69cbc767872a/67bd0a44-a5ee-4b2a-a08a-d62b2b2d2eb2/CF_Penny_icon_white%202.png";

                    const chatHeader = chatWindow.querySelector(
                        ".WACHeader__CenterContainer"
                    );

                    chatHeader.innerHTML = \`<div style="display: flex; gap: 8px; align-items: center">
                            <img src="\${whiteImgUrl}" style="width: 32px; height: 32px;"/>
                            <div style="display: flex; flex-direction: column; justify-content: center; gap: 4px;">
                            <div style="font-size: 22px; font-weight: bold">Penny</div>
                            <div style="font-size: 15px;">Your Virtual Assistant</div>
                            </div>
                        </div>\`;

                    // Chat window round border
                    const chatHeaderMain = chatWindow.querySelector(".WACHeader");
                    chatHeaderMain.style.border = "none";

                    const chatPanel = chatWindow.querySelector(".WACPanelContent");
                    chatPanel.style.background = "#103A41";

                    const chatBody = chatPanel.querySelector(
                        ".WACHomeScreen__bodyWrapper"
                    );

                    chatBody.style.background = "#FFF";
                    chatBody.style.borderTopRightRadius = "8px";
                    chatBody.style.borderTopLeftRadius = "8px";

                    const messagesHeader = document.querySelectorAll(
                        "#WACContainer.WACContainer .WACHeader"
                    );
                    const messageHeaderElement =
                        messagesHeader[messagesHeader.length - 1];
                    messageHeaderElement.style.border = "none";
                    messagesHeader.forEach((header) => {
                        header.style.height = "75px";
                    });

                    const messagesHolder = document.getElementById(
                        "WACMessages--holder"
                    );
                    messagesHolder.style.background = "#103A41";
                    const messagesBody = messagesHolder.querySelector(
                        ".WACMessages__Wrapper"
                    );
                    messagesBody.style.background = "#FFF";
                    messagesBody.style.borderTopRightRadius = "8px";
                    messagesBody.style.borderTopLeftRadius = "8px";

                    const homeButton = document.querySelectorAll(
                        ".WACHeader__BackButton"
                    );
                    const backHomeButton = homeButton[homeButton.length - 1];
                    backHomeButton.style.paddingTop = "0";
                    backHomeButton.style.display = "flex";
                    backHomeButton.style.justifyContent = "center";
                    backHomeButton.style.alignItems = "center";
                    backHomeButton.firstChild.remove();
                    backHomeButton.style.marginLeft = "12px";
                    backHomeButton.innerHTML = \`<img src="\${whiteImgUrl}" style="width: 32px; height: 32px"/>\`;

                    const messageHeaderText = messageHeaderElement.querySelector(
                        ".WACHeader__CenterContainer"
                    );
                    messageHeaderText.style.display = "flex";
                    messageHeaderText.innerHTML = \`<div style="display: flex; flex-direction: column; gap: 4px;">
                            <div style="font-size: 22px; font-weight: bold">Penny</div>
                            <div style="font-size: 15px;">Your Virtual Assistant</div>
                            </div>\`;

                    const messagesContainer = document.querySelector(
                        "#WACContainer.WACContainer .WAC__messages"
                    );
                    messagesContainer.style.overflow = "auto";
                    messagesContainer.style.scrollbarWidth = "none";
                    messagesContainer.style.msOverflowStyle = "none";
                    messagesContainer.style.overflow = "-webkit-panning";
                    const style = document.createElement("style");
                    style.textContent = \`
                            #WACContainer\\.WACContainer .WAC__messages::-webkit-scrollbar {
                            display: none;
                            }
                        \`;
                    document.head.appendChild(style);

                    pollForElement(
                        "class",
                        ".WACHeader__Container .WACHeader__Buttons.WACHeader__RightButtons",
                        function (element) {
                        addRestartButton(element);
                        document
                            .getElementById("restart-button")
                            .addEventListener("click", async () => {
                            await instance.restartConversation();
                            });
                        },
                        100
                    );
                    },
                });
                },
            };
            setTimeout(function () {
                const t = document.createElement("script");
                t.src =
                "https://web-chat.global.assistant.watson.appdomain.cloud/versions/" +
                (window.watsonAssistantChatOptions.clientVersion || "latest") +
                "/WatsonAssistantChatEntry.js";
                document.head.appendChild(t);
            });
      `;
      document.body.appendChild(script);
    }

    return () => {
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);
};
