import React, { useState } from "react";
import NavItem from "./NavItem";
import { Link } from "gatsby";
import { CalculatorIcon } from "@heroicons/react/24/outline";
import { NavDataProps } from "../../types";

const Nav = ({ navData }: NavDataProps) => {
  const { navigation_menu, mortgage_calculator } = navData;
  const [activeId, setActiveId] = useState<string | null>(null);
  const mortgageCalc = (
    mortgage_calculator?.value?.filter(
      (e) => e?.system?.type === "mortgage_calculator_page"
    ) as Queries.kontent_item_mortgage_calculator_page[]
  )?.[0];

  const handleActiveId = (id: string | null = null): void => {
    setActiveId(id === activeId ? null : id);
  };

  return (
    <>
      <div className="flex h-16 text-white bg-ice-500 relative z-90">
        <nav
          role="navigation"
          aria-label="Primary Navigation"
          className="ml-2 xl:ml-6 self-center"
        >
          <ul className="ml-8 lg:mr-10 xl:ml-4 xl:mr-2 flex items-center gap-x-4 xl:gap-6 2xl:gap-x-10">
            {navigation_menu?.value?.map((item: any) => (
              <NavItem
                key={item.id}
                item={item}
                activeId={activeId}
                handleActiveId={handleActiveId}
              />
            ))}
          </ul>
        </nav>
        {mortgageCalc?.url && (
          <Link
            className="lg:hidden xl:inline-flex xl:bg-ice-900 xl:text-white xl:text-lg xl:font-bold px-4 2xl:px-10 xl:items-center xl:ml-auto xl:hover:bg-slate-500"
            to={mortgageCalc?.url}
          >
            <CalculatorIcon className="size-6 text-white mr-2 shrink-0" />
            <span className="whitespace-nowrap shrink-0">
              Mortgage Calculator
            </span>
          </Link>
        )}
      </div>
    </>
  );
};

export default Nav;
