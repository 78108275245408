import React from "react";
import {
  CareIcon,
  ChildCareIcon,
  DentalIcon,
  GPSurgeriesIcon,
  DevelopmentIcon,
  HotelsIcon,
  LeisureIcon,
  PharmaciesIcon,
  PubsIcon,
  RestaurantsIcon,
  RetailIcon,
  BridgingIcon,
  BuyToLetIcon,
  RealEstateIcon,
  RevolvingCreditIcon,
  AssetFinanceIcon,
  UnsecuredLoansIcon,
  GrowthGuarenteeSchemeIcon,
  MerchantCashAdvanceIcon,
} from "../assets/icons/AllIcons";
import { LogoC } from "../assets/icons/LogoC";

export const useDisplaySectorIconByCodename = (
  codename: string,
  className?: string | undefined
) => {
  switch (codename) {
    case "care":
      return <CareIcon className={className ?? undefined} />;
    case "childcare":
      return <ChildCareIcon className={className ?? undefined} />;
    case "dental_practices":
      return <DentalIcon className={className ?? undefined} />;
    case "gp_surgeries":
      return <GPSurgeriesIcon className={className ?? undefined} />;
    case "health":
      return <GPSurgeriesIcon className={className ?? undefined} />;
    case "hotels":
      return <HotelsIcon className={className ?? undefined} />;
    case "leisure":
      return <LeisureIcon className={className ?? undefined} />;
    case "pharmacies":
      return <PharmaciesIcon className={className ?? undefined} />;
    case "pubs":
      return <PubsIcon className={className ?? undefined} />;
    case "restaurants":
      return <RestaurantsIcon className={className ?? undefined} />;
    case "retail":
      return <RetailIcon className={className ?? undefined} />;
    case "other":
      return <LogoC className={className ?? undefined} />;
    case "development":
      return <DevelopmentIcon className={className ?? undefined} />;
    case "bridging":
      return <BridgingIcon className={className ?? undefined} />;
    case "buy_to_let":
      return <BuyToLetIcon className={className ?? undefined} />;
    case "real_estate":
      return <RealEstateIcon className={className ?? undefined} />;
    case "revolving_credit":
      return <RevolvingCreditIcon className={className ?? undefined} />;
    case "unsecured_loans":
      return <UnsecuredLoansIcon className={className ?? undefined} />;
    case "growth_guarentee_scheme":
      return <GrowthGuarenteeSchemeIcon className={className ?? undefined} />;
    case "asset_finance":
      return <AssetFinanceIcon className={className ?? undefined} />;
    case "merchant_cash_advance":
      return <MerchantCashAdvanceIcon className={className ?? undefined} />;
  }
};
