import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CFLogo } from "./../../../assets/icons/CFLogo";
import logoChristieWhite from "./../../../assets/img/logo-christie-finance-white.svg";
import { MobileNestedList } from "./MobileNestedList";

export const MobileNav = ({
  activeId,
  handleActiveId,
  navData,
}: {
  activeId: string | null;
  handleActiveId: (id: string | null) => void;
}) => {
  const { navigation_menu, secondary_navigation_menu } = navData;
  return (
    <div
      className={`fixed overflow-auto right-0 bottom-0 z-70 inset-y-0 bg-slate-600 text-white py-4 px-6 w-full max-w-lg flex flex-col  ${
        activeId ? "translate-x-0" : "translate-x-full"
      } transform ease-out duration-300`}
    >
      <div className="flex justify-between items-center mt-3">
        <div className="bg-ice-500 py-4 px-6 w-auto -ml-6 mr-6">
          <img
            className="w-56 mx-1 text-white"
            src={logoChristieWhite}
            alt="Christie Finance Logo"
          />
        </div>

        <button
          onClick={() => handleActiveId(null)}
          className="text-white p-2 flex items-center justify-end ml-auto hover:bg-red-600"
        >
          <XMarkIcon className="w-7 h-7 font-bold" />
          <span className="sr-only">Close Menu</span>
        </button>
      </div>
      {/* LIST */}
      <nav
        id="nav-primary-sm"
        role="navigation"
        aria-label="Primary Navigation"
        className="mt-3"
      >
        <ul className="text-xl">
          {/* ADD DYNAMIC URL */}
          {[...navigation_menu.value, ...secondary_navigation_menu.value].map(
            (item, idx) => (
              <MobileNestedList
                item={item}
                key={idx}
                activeId={activeId}
                handleActiveId={handleActiveId}
              />
            )
          )}
        </ul>
      </nav>
      <div className="mt-10 flex gap-4"></div>
    </div>
  );
};
