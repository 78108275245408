import React, { useState, ChangeEvent, MouseEvent } from "react";
import { calculateMortgageRepayment } from "../../../utils/utils";
import { mortgageCalculatorText as textData } from "../../../data/text/mortgageCalculatorText";

const YEARS = Array.from({ length: 39 }, (_, i) => i + 2);

type MortgageCalculatorProps = {
  isOpen: boolean;
};

export const MortgageCalculator = ({ isOpen }: MortgageCalculatorProps) => {
  const [values, setValues] = useState({
    length: 2,
    rate: 4.3,
    amount: 0,
  });

  const [monthlyTotal, setMonthlyTotal] = useState("0");

  const isFormValid = () => !values.rate || !values.amount;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    const isInputValid = {
      rate: /^[0-9]*\.?[0-9]*$/,
      amount: /^[0-9,]*$/,
      length: /^(?:[1-9]|[1-9][0-9])$/,
    };

    if (id === "length") {
      setValues((prevValues) => ({
        ...prevValues,
        length: +value,
      }));
    } else {
      if (isInputValid[id as keyof typeof isInputValid]?.test(value)) {
        setValues((prevValues) => ({
          ...prevValues,
          [id]: value,
        }));
      }
    }
  };

  const handleCalculate = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const updatedTotal = calculateMortgageRepayment(
      values.length,
      values.rate,
      values.amount
    );
    if (typeof updatedTotal === "string") {
      setMonthlyTotal((prev) => updatedTotal);
    }
  };
  if (!isOpen) {
    return null;
  }

  return (
    <div
      data-testid="mortgage-calculator"
      className="z-20 max-xl:pt-6 min-h-[45vh]"
    >
      <div className="xl:mt-4 xl:p-6 md:w-160 bg-slate-50 p-2">
        <h3 id="modal-title" className="text-2xl text-ice-500 font-bold">
          {textData.heading}
        </h3>
        <p className="mt-2">{textData.description}</p>
        <form className="w-full px-2 md:px-0 mt-3" action="">
          {/* YEARS */}
          <div className="flex-row gap-2 py-2">
            <label htmlFor="length" className="font-bold text-slate-600">
              {textData.repaymentLabel}
            </label>
            <div className="mt-1 relative ">
              <select
                className="mt-1 w-full rounded-none py-4 transition shadow-sm bg-white
                  -----
                  text-slate-300 border-slate-200
                  placeholder-slate-100
                  focus:border-ice-500 focus:ring-ice-500 focus:text-slate-500 focus:placeholder-slate-200"
                name="years"
                id="length"
                value={values.length}
                onChange={handleInputChange}
              >
                {YEARS.map((item) => (
                  <option key={item} value={item}>
                    {item} years
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* RATE */}
          <div className="flex-row gap-2 py-2">
            <label htmlFor="rate" className="font-bold text-slate-600">
              {textData.interestRate}
            </label>
            <div className="mt-1 relative ">
              <input
                id="rate"
                value={values.rate}
                className="mt-1 w-full rounded-none py-4 transition shadow-sm bg-white
                  -----
                  text-slate-300 border-slate-200
                  placeholder-slate-100
                  focus:border-ice-500 focus:ring-ice-500 focus:text-slate-500 focus:placeholder-slate-200"
                onChange={handleInputChange}
              />
              <span className="absolute right-3 inset-y-0  text-slate-600 flex flex-col justify-center items-center">
                %
              </span>
            </div>
          </div>
          {/* AMOUNT */}
          <div className="flex-row gap-2 py-2">
            <label htmlFor="amount" className="font-bold text-slate-600">
              {textData.amountLabel}
            </label>
            <div className="mt-1 relative text-slate-300 focus-within:text-slate-600">
              <input
                className="mt-1 w-full rounded-none py-4 transition shadow-sm bg-white
                  -----
                  text-slate-300 border-slate-200
                  placeholder-slate-100
                  focus:border-ice-500 focus:ring-ice-500 focus:text-slate-500 focus:placeholder-slate-200  /// pl-6"
                id="amount"
                value={values.amount}
                onChange={handleInputChange}
              />
              <span className="absolute left-3 mt-1 inset-y-0 text-current flex flex-col justify-center items-center">
                £
              </span>
            </div>
          </div>
          {/* BTN */}
          <div className="my-10">
            <button
              disabled={isFormValid()}
              onClick={handleCalculate}
              className="
          font-bold inline-flex items-center justify-center py-4 px-6 group w-full sm:w-auto
           [ bg-ice-500 text-white hover:bg-ice-700 ] disabled:bg-slate-200"
            >
              {textData.calculateBtnLabel}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="shrink-0 w-5 h-5 ml-2 group-hover:animate-bounceRight"
              >
                <path
                  fillRule="evenodd"
                  d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          {/* CALCULATED AMT */}
          <div className="text-center py-2">
            <span className="text-xl">{textData.monthlyRepayment}</span>
            <div className="flex justify-center items-center">
              <span className="pr-2 text-2xl text-slate-600">£</span>
              <h3 className="text-3xl font-bold h-10">{monthlyTotal}</h3>
            </div>
          </div>
          <p className="text-sm pt-5">{textData.disclaimer} </p>
        </form>
      </div>
    </div>
  );
};
