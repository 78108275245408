import React, { ReactNode } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import "../../styles/global.css";
import { useAddWatsonAssistantScript } from "../../hooks/useWatsonAssistantScript";

export const Layout = ({ children }: { children: ReactNode }) => {
  useAddWatsonAssistantScript();
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};
