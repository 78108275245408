import React from "react";

import { Link } from "gatsby";

interface NavMenuLinkProps {
  item: Queries.kontent_item_navigation_menu_link;
  handleActiveId: (id: string | null) => void;
}

const InternalLink = ({ url, params, className, children }) => {
  if (params) {
    return (
      <a
        href={url + params}
        className={className}
        target="_top"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  return url ? (
    <Link className={className} to={url}>
      {children}
    </Link>
  ) : null;
};

const NavMenuLink = ({ item, handleActiveId }: NavMenuLinkProps) => {
  const { description, linked_page, name, query_params } =
    item.elements as Queries.kontent_item_navigation_menu_link_elements;
  const url = (linked_page?.value?.[0] as Christie.KontentItem)?.url ?? "/";

  return (
    <li
      className="border-b-2 border-white/20 py-1"
      onClick={() => {
        handleActiveId(item.id);
      }}
    >
      <InternalLink
        url={url}
        params={query_params?.value || ""}
        className="group block p-6 border-l-6 border-l-transparent hover:bg-white/20 hover:border-l-ice-600"
      >
        <div className="inline-flex items-center group-hover:underline transition">
          <div className="text-xl font-bold">{name?.value}</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="shrink-0 w-6 h-6 ml-1 mt-0.5"
          >
            <path
              fillRule="evenodd"
              d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="text-white/70">{description?.value}</div>
      </InternalLink>
    </li>
  );
};
export default NavMenuLink;
