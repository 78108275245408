import React, { useEffect, useState } from "react";
import { useDisplaySectorIconByCodename } from "../../../hooks/useDisplaySectorIconByCodename";
import NavMenuLink from "./NavMenuLink";
import { ImageElement } from "@kontent-ai/gatsby-components";
type Props = {
  menu: Queries.kontent_item_navigation_sector_menu;
  navRef: React.MutableRefObject<HTMLLIElement | null>;
  activeId: string | null;
  handleActiveId: (id: string | null) => void;
};

export const NavSectorMenu = ({
  menu,
  navRef,
  activeId,
  handleActiveId,
}: Props) => {
  const sectorList = menu?.elements?.navigation_sector?.value?.filter(
    (e) => e?.system?.type === "navigation_sector"
  ) as Queries.kontent_item_navigation_sector[];

  const [sectorId, setSectorId] = useState<string | null>(
    sectorList?.[0]?.id ?? null
  );

  useEffect(() => {
    const clickEventHandler = (event: MouseEvent): void => {
      if (
        activeId == menu.id &&
        navRef.current &&
        !navRef.current.contains(event.target as Node)
      )
        handleActiveId(null);
    };
    document.addEventListener("click", clickEventHandler);
    return () => document.removeEventListener("click", clickEventHandler);
  }, [activeId]);

  return (
    // bottom border
    <div className="border-b-6 border-ice-500 shadow-lg pb-0.5 bg-white">
      <div className="bg-slate-500 text-white w-full">
        <div className="flex justify-between bg-slate-600">
          {/* LEFT CONTENT */}
          <div className="basis-1/2 max-w-3xl mr-6 xl:mr-20 p-10">
            <div className="grid grid-cols-auto-fit-7rem 2xl:grid-cols-4 gap-8">
              {/* GET SECTOR ICON */}
              {sectorList.map((sector) =>
                sectorId === sector.id ? (
                  <button
                    onClick={() => setSectorId(sector.id)}
                    key={sector.id}
                    className="flex flex-col items-center text-center space-y-2 text-lg leading-tight font-bold group text-ice-500 "
                  >
                    <div className="flex flex-col justify-center items-center p-1 text-white mb-1.5 bg-ice-500 ring ring-white  [ w-28 h-28 ]">
                      {useDisplaySectorIconByCodename(
                        sector?.elements?.icon?.value?.[0]?.codename ?? "",
                        "w-20 h-20"
                      )}
                    </div>
                    {sector?.elements?.name?.value}
                  </button>
                ) : (
                  <button
                    onClick={() => setSectorId(sector.id)}
                    key={sector.id}
                    className="flex flex-col items-center text-center space-y-2 text-lg leading-tight font-bold group [ ]"
                  >
                    <div className="flex flex-col justify-center items-center p-1 text-white mb-1.5 [ bg-slate-300 group-hover:bg-ice-500 group-hover:text-slate-700 transition ] [ w-28 h-28 ]">
                      {useDisplaySectorIconByCodename(
                        sector?.elements?.icon?.value?.[0]?.codename ?? "",
                        "w-20 h-20"
                      )}
                    </div>
                    {sector?.elements?.name?.value}
                  </button>
                )
              )}
            </div>
          </div>
          {/* RIGHT CONTENT */}

          {sectorList.map((sector) => {
            return (
              <div
                key={sector.id}
                className={
                  sectorId === sector.id ? "basis-1/2 min-w-xl mb-20" : "hidden"
                }
              >
                {/* BG IMAGES */}
                <div className="grid grid-cols-6">
                  {sector?.elements?.images?.value?.map((img, idx) => (
                    <ImageElement
                      key={idx}
                      image={img}
                      width={160}
                      aspectRatio={1}
                      alt={img?.description ?? ""}
                      loading={"lazy"}
                    />
                  ))}
                </div>
                <div className="relative -mt-6 -ml-6">
                  <h2 className="bg-ice-500 text-white px-6 py-2 inline-block font-bold text-2xl">
                    {sector?.elements?.name?.value}
                    {/* {item.name} */}
                    {/* {sector.elements.sector.value?.[0]?.elements?.name?.value} */}
                  </h2>
                </div>
                <div className="max-w-2xl">
                  <nav
                    id="nav-[sectorName]-sector-lg"
                    role="navigation"
                    aria-label="[SectorName] Sector Navigation"
                    className=""
                  >
                    <ul className="p-6">
                      {(
                        sector?.elements?.links?.value?.filter(
                          (e) => e?.system?.type === "navigation_menu_link"
                        ) as Queries.kontent_item_navigation_menu_link[]
                      )?.map((link) => (
                        <NavMenuLink
                          key={link?.id}
                          item={link}
                          handleActiveId={handleActiveId}
                        />
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
