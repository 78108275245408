import React from "react";
import { TopBanner } from "./Banner";
import { MobileHeader } from "./MobileHeader";
import Nav from "./Nav";
import { useHeaderQuery } from "../../hooks/queries/useHeaderQuery";

export const Header = () => {
  const navData = useHeaderQuery();
  const { secondary_navigation_menu } = navData;

  return (
    <header className="relative w-full">
      <div className="hidden lg:block shadow-lg">
        <TopBanner linkData={secondary_navigation_menu.value} />
        <Nav navData={navData} />
      </div>
      <MobileHeader navData={navData} />
    </header>
  );
};
