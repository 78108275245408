import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React from "react";

type Props = {
  text: string;
  // url: string
  styled: boolean;
};

export const ArrowDownButton = ({ text, styled }: Props) => {
  return (
    <button
      className={
        !styled
          ? "text-lg font-bold leading-tight flex items-center pl-4 pr-2 border-t-4 border-t-transparent hover:bg-black/50 pb-2 pt-1 text-balance"
          : "text-lg font-bold leading-tight flex items-center pl-4 pr-2 border-t-4 border-t-transparent bg-slate-600 -mb-4 pb-4 pt-3 border-t-ice-200 text-balance"
      }
    >
      {text}
      <ChevronDownIcon className="shrink-0 w-5 h-5 mt-1.5 ml-1" />
    </button>
  );
};
