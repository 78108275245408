import { MetaImage } from "../components/Layout/Meta";

export const containsHTML = (str: string) => /<[a-z][\s\S]*>/i.test(str);

export const calculateMortgageRepayment = (
  years: number,
  rate: number,
  amount: number
) => {
  if (rate === 0) {
    // console.log("0 rate!!");
    return 0;
  }
  //   if (!years || rate === 0 || !amount) return 0;

  const monthlyInterest = rate / 12 / 100;
  const totalNumofPayments = years * 12;

  const monthlyRepayment =
    (amount *
      monthlyInterest *
      Math.pow(1 + monthlyInterest, totalNumofPayments)) /
    (Math.pow(1 + monthlyInterest, totalNumofPayments) - 1);

  if (!isNaN(monthlyRepayment)) {
    return Math.round(monthlyRepayment).toLocaleString();
  } else {
    return "0";
  }
};

export const hasOnlyNumbers = (str: string) => /^\d+$/.test(str);

export const removeEmptyAttributes = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (Array.isArray(value) && value.length === 0) return acc;
    if (!value) return acc;
    acc[key] = value;

    return acc;
  }, {});
};

export const mapOptionToLabel = (
  values: string[],
  obj: { value: string; label: string }[]
) => {
  return values
    .map((value) => obj.find((option) => option.value === value)?.label)
    .join(", ");
};

const filtersMap = [
  { codename: "", name: "By Sector" },
  { codename: "care", name: "Care" },
  { codename: "childcare", name: "Childcare" },
  { codename: "dental_practices", name: "Dental Practices" },
  { codename: "dental", name: "Dental" },
  { codename: "health__medical_", name: "Health (Medical)" },
  { codename: "hotels", name: "Hotels" },
  { codename: "leisure", name: "Leisure" },
  { codename: "pharmacies", name: "Pharmacies" },
  { codename: "pubs", name: "Pubs" },
  { codename: "restaurants", name: "Restaurants" },
  {codename:'pubs_restaurants',name:"Pubs & Restaurants"},
  { codename: "retail", name: "Retail" },
  {codename:"other",name:"Other"},

  { codename: "", name: "By Service" },
  { codename: "bridging", name: "Bridging" },
  { codename: "bridging_loans", name: "Bridging Loans" },
  { codename: "commercial_mortgages", name: "Commercial Mortgages" },
  { codename: "corporate_debt_advisory", name: "Corporate Debt Advisory" },
  {
    codename: "unsecured_and_asset_finance",
    name: "Unsecured and Asset Finance",
  },
  { codename: "development", name: "Development" },
  { codename: "investment", name: "Investment" },
  {codename:"investment_development",name:"Investment & Development"},
  {codename:'buy_to_let_mortgages',name:"Buy-To-Let Mortgages"},
  {
    codename: "unsecured_asset_finance",
    name: "Unsecured and Asset Finance",
  },
  { codename: "", name: "By Location" },
  { codename: "east_anglia", name: "East Anglia" },
  { codename: "east_midlands", name: "East Midlands" },
  { codename: "london", name: "London" },
  { codename: "north_east", name: "North East" },
  { codename: "northern_ireland", name: "Northern Ireland" },
  { codename: "north_west", name: "North West" },
  { codename: "scotland", name: "Scotland" },
  { codename: "south_east", name: "South East" },
  { codename: "south_west", name: "South West" },
  { codename: "wales", name: "Wales" },
  { codename: "west_midlands", name: "West Midlands" },
  { codename: "yorkshire_humber", name: "Yorkshire and the Humber" },
];

export const getCodenameLabel = (obj: string[]) => {
  if (obj.length === 0) return obj;
  return obj
    .map((tag) => filtersMap.find((item) => item.codename === tag)?.name)
    .filter(Boolean);
};

export const getLabelCodeName = (obj: string[] | undefined) => {
  if (obj?.length === 0) return obj;
  if (Array.isArray(obj)) {
    obj.map((label) => filtersMap.find((item) => item.name === label)?.codename).filter(Boolean);
  }
  return
};

export const generateSectorServiceQueryParams = (
  sectorsObj: string[] | undefined,
  servicesObj: string[] | undefined
) => {
  if (sectorsObj?.length === 0 && servicesObj?.length === 0) return "";

  const sectors = sectorsObj?.length
    ? sectorsObj.map((sector) => `sector=${sector}`).join("&")
    : "";
  const services = servicesObj?.length
    ? servicesObj.map((service) => `service=${service}`).join("&")
    : "";

  return `?${sectors ? sectors : ""}${
    sectors && services ? "&" : ""
  }${services}`;
};

export const hasValidProtocol = (url: string): boolean => {
  const regex = /^https:\/\//;
  return regex.test(url);
};



export const generateHeroImageData = (
  items:
    | Queries.Maybe<readonly Queries.Maybe<Queries.kontent_item>[]>
    | undefined
): MetaImage[] => {
  const heroList = (items ?? []).filter(
    (e) => e?.system?.type == "hero" || e?.system?.type == "hero_with_icon"
  ) as (Queries.kontent_item_hero | Queries.kontent_item_hero)[];
  const images = heroList?.[0]?.elements?.image?.value ?? [];
  return images.map((image) => ({
    src: image?.url ? image?.url + "?w=1000&h=352&auto=format&fit=crop" : "",
    sizes: "100vw",
    srcSet: image?.url
      ? image?.url +
        "?w=1000&h=352&auto=format&fit=crop 1000w," +
        image?.url +
        "?w=768&h=240&auto=format&fit=crop 768w"
      : "",
    description: image?.description ?? "",
  }));
};
