exports.validGTMTrackingId = function (options) {
  return options.trackingId && options.trackingId.trim() !== "";
};
exports.getCookie = function (name) {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
};
exports.isEnvironmentValid = function (environments) {
  var currentEnvironment = process.env.ENV || process.env.NODE_ENV || "development";
  return environments.includes(currentEnvironment);
};