import React from "react";
import { withPrefix } from "gatsby";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import MobileNavSectorMenu from "./MobileSectorMenu";
import MobileNavMenu from "./MobileNavMenu";
// import MobileNavSectorMenu from "../MobileNavSectorMenu";
// import MobileNavMenu from "../MobileNavMenu";

type NavList = {
  id: number;
  title: string;
  subList?: string[];
  url?: string;
};
type Props = {
  item: NavList;
  activeId: number | null;
  handleActiveId: (id: number | null) => void;
};

export const MobileNestedList = ({ item, activeId, handleActiveId }: Props) => {
  return (
    <li className="py-2 border-b border-white/10">
      {item.system.type == "navigation_menu_link" ? (
        <a
          href={withPrefix(item.elements?.linked_page?.value?.[0]?.url)}
          className="relative font-bold flex items-center px-4 py-2 [ hover:bg-ice-500 ] text-balance"
          onClick={() => handleActiveId(null)}
        >
          {item.elements?.name?.value}
        </a>
      ) : (
        <div
          className="relative font-bold flex items-center px-4 py-2 [ hover:bg-ice-500 ] cursor-pointer text-balance"
          onClick={() => handleActiveId(item.id)}
        >
          {item.elements?.name?.value}
          <ArrowRightIcon className="ml-2 w-5 h-5" />
        </div>
      )}
      {activeId === item.id && (
        <section className="lg:block lg:px-10 lg:mt-3 lg:z-90 lg:absolute lg:overflow-auto lg:inset-x-0">
          {item.system.type == "navigation_sector_menu" && (
            <MobileNavSectorMenu item={item} handleActiveId={handleActiveId} />
          )}
          {item.system.type == "navigation_menu" && (
            <MobileNavMenu item={item} handleActiveId={handleActiveId} />
          )}
        </section>
      )}
    </li>
  );
};
