import React from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";

type PageContentText = {
  [key: string]: {
    value: string;
  };
};

type Props = {
  modalTitle?: string;
  isOpen: boolean;
  closeModal: () => void;
  children: React.ReactNode;
};

const ModalWrapper = ({ modalTitle, isOpen, closeModal, children }: Props) => {
  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={closeModal}
      open={isOpen}
    >
      <div className="fixed inset-0 bg-black bg-opacity-80" />
      <div className="relative flex min-h-screen items-center justify-center p-4">
        <DialogPanel className="relative w-auto max-w-8xl overflow-y-hidden bg-white p-6 shadow-lg">
          <div className="absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              className="absolute top-3 right-3 text-slate-500 hover:bg-ice-500 hover:text-white"
              onClick={closeModal}
            >
              <XMarkIcon className="w-8 h-8" />
            </button>
          </div>

          <div className="pt-6 ">
            <div className="-ml-6 sm:-mt-3">
              <DialogTitle
                as="h2"
                className="bg-ice-500 text-white inline-block font-semibold px-4 py-3 text-2xl"
              >
                {/* <DialogTitle as="h2" className="heading--sticker text-2xl"> */}
                {modalTitle}
              </DialogTitle>
            </div>
          </div>
          {children}
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default ModalWrapper;
