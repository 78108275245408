import React, { useRef } from "react";
import { Link } from "gatsby";
import { NavSectorMenu } from "./../NavSectorMenu";
import { ArrowDownButton } from "./../../../common/button/ArrowDownButton";
import { NavMenu } from "./../NavMenu";

type Props = {
  item: any;
  activeId: string | null;
  handleActiveId: (id: string | null) => void;
};

const NavItem = ({ item, activeId, handleActiveId }: Props) => {
  const navRef = useRef<HTMLLIElement | null>(null);

  return (
    <li ref={navRef}>
      {item?.system?.type === "navigation_menu_link" ? (
        <Link
          to={item.elements.linked_page?.value?.[0]?.url}
          className="text-lg font-bold leading-tight flex items-center px-4 border-t-4 border-t-transparent hover:bg-black/50 pb-2 pt-1 text-balance"
          onClick={() => handleActiveId(null)}
        >
          {item.elements?.name?.value}
        </Link>
      ) : (
        <div
          onClick={() => {
            handleActiveId(item.id);
          }}
        >
          <ArrowDownButton
            styled={activeId === item.id}
            text={item.elements.name.value}
          />
        </div>
      )}

      <section
        className={
          "lg:px-10 lg:mt-3 lg:z-80 lg:absolute lg:overflow-auto lg:inset-x-0 hidden lg:w-full " +
          (activeId === item.id ? "lg:block" : "")
        }
      >
        {item?.system?.type == "navigation_sector_menu" && (
          <NavSectorMenu
            key={item.id}
            menu={item}
            navRef={navRef}
            activeId={activeId}
            handleActiveId={handleActiveId}
          />
        )}
        {item?.system?.type == "navigation_menu" && (
          <NavMenu
            item={item}
            navRef={navRef}
            activeId={activeId}
            handleActiveId={handleActiveId}
          />
        )}
      </section>
    </li>
  );
};

export default NavItem;
