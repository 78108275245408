import { Link } from "gatsby";
import React, { useState } from "react";

import logoChristieFinanceColoured from "../../../assets/img/logo-christie-finance-coloured.svg";
import ModalWrapper from "../../common/wrapper/ModalWrapper";
import { MortgageCalculator } from "./MortgageCalculator";

export const TopBanner = ({
  linkData,
}: {
  linkData: Queries.kontent_item_navigation_menu;
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <div className="flex justify-between items-center w-full relative px-6 py-3">
      <ModalWrapper
        modalTitle={"Mortgage Calculator"}
        isOpen={modalIsOpen}
        closeModal={toggleModal}
      >
        <MortgageCalculator isOpen={modalIsOpen} />
      </ModalWrapper>
      <Link to="/" className="block" aria-label="Return to Home Page">
        <img
          src={logoChristieFinanceColoured}
          alt="Christie &amp; Finance Logo"
          className="w-auto h-12"
        />
      </Link>
      <div className="flex items-center justify-end px-3 flex-1 gap-x-6">
        <nav role="navigation" aria-label="Top Navigation">
          <ul className="flex items-center justify-end gap-2 text-sm">
            {linkData?.map((item, idx) => {
              const { name, linked_page, id } = item.elements;
              return (
                <li className="py-1" key={idx}>
                  <Link
                    to={linked_page?.value[0].url}
                    className="hover:bg-white/25 px-2 font-semibold hover:text-ice-600 hover:decoration-2 hover:underline-offset-2 first:pr-4 flex items-center gap-2"
                  >
                    {name.value}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};
