import React from "react";
import { Link } from "gatsby";
import logoChristieWhite from "../../assets/img/logo-christie-finance-white.svg";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { CalculatorIcon } from "@heroicons/react/24/outline";
import { useFooterQuery } from "../../hooks/queries/useFooterQuery";
import CookieConsent from "./CookieConsent";

export const Footer = () => {
  const data = useFooterQuery();
  return (
    <footer
      id="footer"
      role="contentinfo"
      className="print:hidden border-t-20 border-ice-500 bg-ice-900 text-white font-normal"
    >
      {/*{# About and Contact / Social #}*/}
      <section className="py-6 lg:py-10 2xl:py-16">
        <div className="container">
          <div className="grid gap-12 md:grid-cols-2 xl:grid-cols-[1fr,2fr,1fr]">
            <div className="">
              <h2 className="sr-only">Christie Finance</h2>
              <div className="w-full max-w-72">
                <img
                  src={logoChristieWhite}
                  alt="Christie Finance Logo"
                  className="w-full"
                  width="300"
                  height="50"
                />
              </div>
              <div className="sm:ml-3">
                <div className="mt-10 leading-relaxed whitespace-pre-line">
                  {data?.postal_address?.value}
                </div>
                <div className="mt-10">
                  <a
                    href={"tel:" + data?.phone?.value}
                    className="text-2xl font-bold"
                  >
                    {data?.phone?.value}
                  </a>
                  <br />
                  <a
                    href={"mailto:" + data?.email?.value}
                    className="text-lg hover:underline"
                  >
                    {data?.email?.value}
                  </a>
                </div>
              </div>
            </div>
            <div className="md:col-start-1 xl:col-start-2 md:row-start-2 xl:row-start-1 md:col-span-2 xl:col-span-1 flex flex-wrap gap-12 justify-between sm:px-3 xl:border-r xl:border-white/20 2xl:mr-6">
              {data.footer_navigation_menu.value.map(
                (menu: Queries.kontent_item_navigation_menu) => (
                  <div key={menu.id} className="w-full">
                    <h3 className="text-xl font-bold">
                      {menu?.elements?.name?.value}
                    </h3>
                    <ul className="mt-1 leading-relaxed grid sm:grid-cols-2 gap-x-16">
                      {(
                        menu?.elements?.primary_navigation_links?.value?.filter(
                          (e) => e?.system?.type === "navigation_menu_link"
                        ) as Queries.kontent_item_navigation_menu_link[]
                      ).map((item) => (
                        <li key={item?.id}>
                          <Link
                            to={
                              ((
                                item?.elements?.linked_page
                                  ?.value?.[0] as Christie.KontentItemWithUrl
                              )?.url || "") +
                              (item?.elements?.query_params?.value || "")
                            }
                            className="inline-block text-15 text-white/50 hover:underline hover:text-white hover:bg-ice-500/50 px-2 py-1"
                          >
                            {item?.elements?.name?.value}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              )}
            </div>
            <div className="md:col-start-2 xl:col-start-3 md:row-start-1 grid sm:flex sm:flex-wrap md:flex-col justify-between gap-6">
              <div className="flex-1">
                <h2 className="heading--sticker text-2xl -ml-6">
                  {data.social_media_header.value}
                </h2>
                <p className="text-white/70 mt-3">
                  {data.social_media_description.value}
                </p>
                <div className="flex items-center gap-2 mt-3">
                  {data?.facebook?.value && (
                    <a
                      className="flex items-center justify-center text-white w-11 h-11 p-2.5 hover:bg-white/10 motion-safe:hover:scale-120"
                      href={data?.facebook?.value}
                      title="Follow Christie &nbsp; Co on Facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"></path>
                      </svg>
                    </a>
                  )}
                  {data.linkedin.value && (
                    <a
                      className="flex items-center justify-center text-white w-10 h-10 p-2.5 hover:bg-white/10 motion-safe:hover:scale-120"
                      href={data.linkedin.value}
                      title="Follow Christie Finance on LinkedIn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        fill="currentColor"
                      >
                        <path d="M100.3 480H7.4V180.9h92.9V480zM53.8 140.1C24.1 140.1 0 115.5 0 85.8 0 56.1 24.1 32 53.8 32c29.7 0 53.8 24.1 53.8 53.8 0 29.7-24.1 54.3-53.8 54.3zM448 480h-92.7V334.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V480h-92.8V180.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V480z" />
                      </svg>
                    </a>
                  )}
                  {data.instagram.value && (
                    <a
                      className="flex items-center justify-center text-white w-10 h-10 p-2.5 hover:bg-white/10 motion-safe:hover:scale-120"
                      href={data.instagram.value}
                      title="Follow Christie Finance on Instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        fill="currentColor"
                      >
                        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                      </svg>
                    </a>
                  )}
                  {data.x.value && (
                    <a
                      className="flex items-center justify-center text-white w-10 h-10 p-2.5 hover:bg-white/10 motion-safe:hover:scale-120"
                      href={data.x.value}
                      title="Follow Christie Finance on X"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M3.375 3.864c-.007.011.569.796 1.277 1.742l3.342 4.47 2.055 2.749-.132.149-3.357 3.627c-1.771 1.917-3.225 3.495-3.229 3.513-.007.018.302.032.747.028l.757-.004.249-.274c.139-.149 1.426-1.54 2.862-3.094s2.653-2.87 2.702-2.926l.089-.103.711.953 2.393 3.204 1.685 2.247h2.578c2.144 0 2.574-.007 2.56-.046-.011-.028-.665-.91-1.454-1.966a1252.36 1252.36 0 0 1-2.005-2.681c-.316-.42-1.245-1.668-2.069-2.766a100.111 100.111 0 0 1-1.501-2.034c0-.025 4.359-4.758 5.842-6.34l.423-.452h-.779l-.775.004-.907.981c-.498.54-1.717 1.863-2.713 2.934l-1.803 1.949-2.187-2.923-2.19-2.926-2.575-.019c-1.419-.01-2.589-.007-2.596.004zm6.109 3.15c.843 1.131 2.144 2.87 2.891 3.869.747.996 2.404 3.214 3.684 4.925l2.358 3.158c.021.032-.231.043-1.152.043h-1.177l-2.333-3.122C8.371 8.693 5.655 5.059 5.623 5.005c-.021-.032.22-.043 1.149-.043h1.177l1.535 2.052z"></path>
                      </svg>
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        fill="currentColor"
                      >
                        <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                      </svg> */}
                    </a>
                  )}
                </div>
              </div>
              <a
                href={data.mortgage_calculator?.value?.[0]?.url ?? ""}
                className="flex-1 block self-end lg:-mx-6 p-6 bg-slate-900/50 border-b-8 border-ice-500 hover:bg-ice-600"
              >
                <div className="flex items-end gap-2">
                  <div>
                    <CalculatorIcon className="size-10 text-white shrink-0" />
                  </div>
                  <strong className="text-xl font-bold block">
                    Mortgage Calculator
                  </strong>
                </div>
                <div className="px-2">
                  <div className="block hover:underline transition mt-4">
                    {data.mortgage_calculator_description.value}
                    <ArrowRightIcon className="inline-block w-5 h-5 ml-1" />
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/*{# Copyright and FCA #}*/}
      <section className="py-8">
        <div className="container">
          <p>{data.footer_text.value}</p>
          <p className="text-xs text-white/50 leading-relaxed max-w-4xl">
            {data.footer_disclaimer.value}
          </p>
          <ul className="flex flex-wrap gap-2 gap-x-3 text-sm text-white/50 mt-6">
            {data.footer_links.value.map((item, index) => (
              <li key={item.id}>
                <a
                  href={item?.url ?? ""}
                  className="hover:text-white hover:underline hover:bg-ice-500/50 px-2 py-1"
                >
                  {item?.elements?.name?.value ?? ""}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <CookieConsent />
    </footer>
  );
};
