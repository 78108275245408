var _require = require('./services'),
  initializeAndTrackGoogleTagManager = _require.initializeAndTrackGoogleTagManager;
var _require2 = require('./helper'),
  isEnvironmentValid = _require2.isEnvironmentValid;
exports.initializeAndTrack = function (location) {
  var options = window.gatsbyPluginGDPRCookiesOptions;
  if (isEnvironmentValid(options.environments)) {
    if (location === undefined || location === null) {
      console.error('Please provide a reach router location to the initializeAndTrack function.');
    } else {
      initializeAndTrackGoogleTagManager(options.googleTagManager, location);
    }
  }
};