import React from "react";
import { withPrefix } from "gatsby";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

type Props = {
  item: any;
  handleActiveId: (id: string | null) => void;
};

const MobileNavMenu = ({ item, handleActiveId }: Props) => {
  return (
    <div className="fixed overflow-auto z-70 inset-y-0 right-0 top-0 bg-slate-600 text-white py-4 px-6 w-full">
      <div className="grid h-full">
        <div>
          <div className="flex flex-wrap flex-row-reverse gap-6 justify-between items-center -ml-6">
            <button
              onClick={() => handleActiveId("open")}
              className="hover:underline focus:underline focus:outline-none // flex items-center transition"
            >
              <ArrowLeftIcon className="mr-1 w-4 h-4" />
              <span className="text-sm">Back</span>
            </button>

            <h2 className="bg-ice-500 text-white px-6 py-2 inline-block font-bold text-xl">
              {item.elements.name.value}
            </h2>
          </div>
          <nav role="navigation" aria-labelledby="Navigation" className="mt-6">
            <ul className="grid gap-4">
              {item.elements.primary_navigation_links.value.map((link) => (
                <li key={link.id} onClick={() => handleActiveId(null)}>
                  <a
                    href={withPrefix(
                      link?.elements?.linked_page?.value?.[0]?.url
                    )}
                    className="font-bold text-lg"
                  >
                    {link.elements.name.value}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        {item.elements.secondary_links_heading.value &&
          item.elements.secondary_navigation_links.value.length && (
            <section>
              <div className="mt-8 pt-8 border-t border-white/20 -mx-6">
                <div className="heading--sticker text-lg">
                  {item.elements.secondary_links_heading.value}
                </div>
                <ul className="text-lg font-medium grid grid-cols-auto-fit-250 xl:grid-cols-2 gap-x-10 gap-y-4 p-6">
                  {item.elements.secondary_navigation_links.value.map(
                    (link) => (
                      <li key={link.id} onClick={() => handleActiveId(null)}>
                        <a
                          href={withPrefix(
                            link?.elements?.linked_item?.value?.[0]?.url
                          )}
                          className="inline-flex items-center hover:underline"
                        >
                          {link.elements.name.value}
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </section>
          )}
      </div>
    </div>
  );
};

export default MobileNavMenu;
