import { Bars3Icon, CalculatorIcon } from "@heroicons/react/24/outline";
import { Link, withPrefix } from "gatsby";
import React, { useState } from "react";
import logoChristieWhite from "./../../../assets/img/logo-christie-finance-white.svg";
import { CFLogo } from "./../../../assets/icons/CFLogo";
import { MobileNav } from "./../MobileNav/index";
import { NavDataProps } from "../../types";

export const MobileHeader = ({ navData }: NavDataProps) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const handleActiveId = (id: string | null = null): void => {
    setActiveId(id === activeId ? null : id);
  };

  return (
    <section className="block lg:hidden shadow-lg ">
      <div className="flex justify-between  bg-ice-500 text-white h-16">
        {/* LOGO */}
        <div className="flex flex-col justify-center items-center ml-3 mr-6 sm:mx-6">
          <a href={withPrefix("/")}>
            <img
              src={logoChristieWhite}
              alt="Christie Finance Logo"
              className="w-56 mx-1 text-white"
            />
          </a>
        </div>

        {/* RIGHT MENU */}
        <div className="mr-4 flex items-center justify-end gap-x-3 sm:gap-x-5">
          {navData.mortgage_calculator?.value[0]?.url && (
            <Link
              className="block"
              to={navData.mortgage_calculator?.value[0]?.url}
            >
              <CalculatorIcon className="size-7" />
            </Link>
          )}

          <button className="block" onClick={() => handleActiveId("open")}>
            <Bars3Icon className="size-8" />
            <span className="sr-only">Menu</span>
          </button>
        </div>

        {/* SIDE MENU */}
        {activeId && (
          <MobileNav
            activeId={activeId}
            handleActiveId={handleActiveId}
            navData={navData}
          />
        )}
      </div>
    </section>
  );
};
