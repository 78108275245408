export const mortgageCalculatorText = {
  heading: "Try our mortgage calculator",
  description:
    "Find out how much you will pay each month for your loan by trying out our business mortgage calculator.",
  interestRate: "Interest Rate",
  repaymentLabel: "Repayment period",
  interestRateLabel: "Interest Rate",
  amountLabel: "Mortgaged Amount",
  calculateBtnLabel: "Calculate your repayment",
  monthlyRepayment: "Monthly repayments:",
  disclaimer:
    "This Loan Calculator is designed purely to provide you with an illustration and should not be relied upon as a quotation. Christie Finance operate as an intermediary and are not a principal lender.",
};
